import React, { useState } from "react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { FaStar } from "react-icons/fa";
import Slider from "react-slick";
import aboutLanding from "../assets/coverglass3.jpeg";
import akash from "../assets/akash.jpeg";
import rohit from "../assets/rohit.png";
import work from "../assets/work.jpg";
import priya from "../assets/priya.png";
import rajesh from "../assets/rajesh.png";
import anjali from "../assets/anjali.png";
import { Link } from "react-router-dom";

const About = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const PreviousButton = (props) => {
    const { onClick, disabled } = props;
    return (
      <div
        className={`absolute top-1/2 transform -translate-y-1/2 -left-6 text-3xl ${disabled ? "text-gray-300" : "text-black hover:text-gray-400"
          } p-2 hidden group-hover:flex`}
        style={{ zIndex: "39" }}
      >
        <button
          className="flex items-center justify-center w-8 h-10"
          onClick={onClick}
          disabled={disabled}
        >
          <BsChevronLeft />
        </button>
      </div>
    );
  };

  const NextButton = (props) => {
    const { onClick, disabled } = props;
    return (
      <div
        className={`absolute top-1/2 transform -translate-y-1/2 -right-6 text-3xl ${disabled ? "text-gray-300" : "text-black hover:text-gray-400"
          } p-2 hidden group-hover:flex`}
        style={{ zIndex: "39" }}
      >
        <button
          className="flex items-center justify-center w-8 h-10"
          onClick={onClick}
          disabled={disabled}
        >
          <BsChevronRight />
        </button>
      </div>
    );
  };

  const testimonials = [
    {
      imgSrc: priya,
      review: (
        <>
          <FaStar />
          <FaStar />
          <FaStar />
          <FaStar />
          <FaStar />
        </>
      ),
      text: "UniqueGlass delivered exceptional quality and service. The innovative designs and attention to detail truly transformed our space. Highly recommended!",
      name: "Priya Sharma",
      company: "Google Inc.",
    },
    {
      imgSrc: rohit,
      review: (
        <>
          <FaStar />
          <FaStar />
          <FaStar />
          <FaStar />
          <FaStar />
        </>
      ),
      text: "The craftsmanship and professionalism of UniqueGlass were outstanding. Their designs exceeded our expectations and added a touch of elegance to our project.",
      name: "Rohit Kapoor",
      company: "Google Inc.",
    },
    {
      imgSrc: rajesh,
      review: (
        <>
          <FaStar />
          <FaStar />
          <FaStar />
          <FaStar />
          <FaStar />
        </>
      ),
      text: "Working with UniqueGlass was a pleasure. Their attention to detail and innovative approach created stunning results for our space.",
      name: "Rajesh Verma",
      company: "Google Inc.",
    },
    {
      imgSrc: anjali,
      review: (
        <>
          <FaStar />
          <FaStar />
          <FaStar />
          <FaStar />
          <FaStar />
        </>
      ),
      text: "UniqueGlass combines creativity with precision. Their work was pivotal in achieving the vision we had for our design project.",
      name: "Anjali Gupta",
      company: "Google Inc.",
    },
  ];

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <PreviousButton onClick={null} disabled={currentIndex === 0} />,
    nextArrow: (
      <NextButton
        onClick={null}
        disabled={currentIndex >= testimonials.length - 3}
      />
    ),
    beforeChange: (oldIndex, newIndex) => setCurrentIndex(newIndex),
  };

  return (
    <>
      <div className="relative w-full">
        <div>
          <img
            src={aboutLanding}
            alt="work-landing"
            className=" w-screen  md:h-[500px]   object-cover"
          />
        </div>
        <div className="absolute text-4xl font-bold text-center text-white transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
          <div
            className={`mb-4 font-bold text-4xl md:text-7xl animate-slide-in`}
          >
            About us
          </div>

          <div className="mb-6 text-sm font-bold animate-slide-in">
            <h2>
              <Link to="/">Home</Link>
              &gt; About
            </h2>
          </div>
        </div>
      </div>
      <div className="mt-10 lg:m-7 p-4 lg:w-[80%] lg:mx-auto">
        <div className="lg:px-4">
          <p className="mb-3 text-sm font-semibold text-lime-500">
            SOME WORDS ABOUT ME
          </p>
          <p className="mb-3 text-xl font-bold lg:text-4xl">My work success</p>
        </div>
        <div className="flex flex-wrap mb-16 -m-4">
          <div className="p-4 xl:w-1/4 md:w-1/2">
            <div className="border-[1px] border-gray-200 p-6">
              <h1 className="mb-6 text-5xl font-semibold text-lime-500">7</h1>
              <h4 className="mb-3 text-lg font-bold">Years in this field</h4>
              <p className="text-gray-500">
                With 7 years of experience, Aakash Sukhadiya has mastered the
                art of glass design, offering unique and customized solutions
                for clients.
              </p>
            </div>
          </div>
          <div className="p-4 xl:w-1/4 md:w-1/2">
            <div className="border-[1px] border-gray-200 p-6">
              <h1 className="mb-6 text-5xl font-semibold text-lime-500">
                500+
              </h1>
              <h4 className="mb-3 text-lg font-bold">Clients happy</h4>
              <p className="text-gray-500">
                Over 500 satisfied clients trust UniqueGlass for their
                exceptional glasswork, showcasing Aakash Sukhadiya commitment to quality
                and innovation.
              </p>
            </div>
          </div>
          <div className="p-4 xl:w-1/4 md:w-1/2">
            <div className="border-[1px] border-gray-200 p-6">
              <h1 className="mb-6 text-5xl font-semibold text-lime-500">
                650+
              </h1>
              <h4 className="mb-3 text-lg font-bold">Finished works</h4>
              <p className="text-gray-500">
                UniqueGlass has completed over 650 projects, ranging from custom
                mirrors to architectural glass installations, ensuring high
                standards and customer satisfaction.
              </p>
            </div>
          </div>
          <div className="p-4 xl:w-1/4 md:w-1/2">
            <div className="border-[1px] border-gray-200 p-6">
              <h1 className="mb-6 text-5xl font-semibold text-lime-500">7</h1>
              <h4 className="mb-3 text-lg font-bold">Years work</h4>
              <p className="text-gray-500">
                UniqueGlass, founded by Aakash Sukhadiya, has been at the
                forefront of innovative glass design for over 7 years, blending
                artistry with functionality.
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-8 mb-8 lg:flex-row">
          <div className="relative flex flex-col justify-between w-full p-10 text-white bg-lime-600 lg:w-2/3">
            <div className="font-semibold text-md xl:text-xl">
              Hello, I am Aakash Sukhadiya, the owner of UniqueGlass. We specialize in creating innovative and high-quality glass solutions, from custom mirrors to toughened glass partitions. With over 7 years of experience in the field, our team is dedicated to transforming your spaces with elegant and functional designs. We pride ourselves on delivering exceptional craftsmanship and customer service, ensuring that each project reflects our commitment to excellence. At UniqueGlass, we turn your vision into reality.
            </div>
            <br />
            <div className="flex flex-row items-center gap-5">
              <p>- Aakash Sukhadiya, Proprietor</p>
            </div>
          </div>
          <div className="flex justify-center lg:block lg:w-1/3">
            <img
              src={akash}
              alt="Owner"
              className="object-contain w-full lg:w-72"
            />
          </div>
        </div>
        <div className="flex flex-col gap-8 mb-16 sm:flex-row">
          <div>
            <img
              src={work}
              alt=""
              className="object-contain w-full lg:w-80"
            />
          </div>
          <div>
            <img
              src="https://woodmart.b-cdn.net/wp-content/uploads/2021/03/w-about-me-img-3-opt.jpg.webp"
              alt=""
            />
          </div>
        </div>
        <div>
          <div className="w-[85%] mx-auto text-center mb-8">
            <h5 className="mb-3 font-semibold text-lime-500">
              {" "}
              SEEMINGLY ELEGANT DESIGN
            </h5>
            <h2 className="mb-3 text-2xl font-bold xl:text-4xl">
              Reviews about my work
            </h2>
            <p className="lg:w-[40%] mx-auto text-justify text-sm text-gray-500 leading-relaxed">
              At UniqueGlass, we strive for perfection. While a client with
              specific concerns is manageable, an unhappy client who can't
              identify the issue challenges us to refine our craft even further.
              Our goal is to deliver exceptional results that leave no room for
              dissatisfaction.
            </p>
          </div>
          <div className="relative group">
            <Slider {...settings}>
              {testimonials.map((testimonial, index) => (
                <div key={index} className="p-4 lg:w-1/3">
                  <div className="relative flex flex-col items-center h-full px-2 py-6 overflow-hidden text-center rounded-lg">
                    <img
                      src={testimonial.imgSrc}
                      alt=""
                      className="w-24 h-24 mb-4 rounded-full"
                    />
                    <div className="text-center">
                      <div className="flex justify-center mb-2 text-yellow-500">
                        {testimonial.review}
                      </div>
                      <p className="text-sm text-gray-500 text-balance">
                        {testimonial.text}
                      </p>
                      <p className="mt-4 font-semibold">
                        {testimonial.name}
                        <span className="text-gray-500">
                          {" "}
                          - {testimonial.company}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
