import React from "react";
import logo from "../../assets/logo.jpg";
import { Link } from "react-router-dom";
import { CiLocationOn } from "react-icons/ci";
import { CiMobile3 } from "react-icons/ci";
import { CiMail } from "react-icons/ci";
import { Col, Row } from "antd";

const Footer = () => {
  return (
    <div
      className="w-full mt-24 text-white bg-black pb-14"
    >
      <footer className="container grid py-10">
        <Row>
          <Col lg={8} span={24} xl={8}>
            <div className="pt-5 text-center md:text-start">
              <span>
                <Link to="/" onClick={() => window.scrollTo(0)}>
                  <img
                    src={logo}
                    alt="Logo"
                    className="object-contain w-36 md:w-32 sm:ml-14"
                  />
                </Link>
              </span>
              <div className="m-10">
                <p className="mt-2 text-sm">
                  Welcome to Unique Glass, your trusted partner for all
                  glass-related services and products.
                </p> <br />
                <p className="mt-2 text-sm">
                  years of experience in the industry, we are dedicated to
                  delivering top-quality glass solutions tailored to meet the
                  unique needs of our clients.
                </p>
              </div>
            </div>
          </Col>

          <Col lg={6} span={24} xl={6}>
            <div className="flex flex-col items-start pt-10 mx-auto md:pt-5 md:mx-0">
              <div className="">
                <h1 className="pb-12 ml-4 text-2xl font-bold"> Get in Touch</h1>
              </div>

              <div className="flex flex-col space-y-5">
                <div className="flex gap-2">
                  <CiLocationOn className="w-12" />
                  <Link
                    to="https://maps.app.goo.gl/Tc2dgbEe2Evjgq6h6"
                    target="_blank"
                  >
                    13,Dharamajivan society near Chamunda dairy hirawadi road 382345
                  </Link>
                </div>

                <div className="flex gap-2">
                  <CiMobile3 className="w-10" />
                  <Link to="tel:+918980273288" target="_blank">
                    +91 89802 73288
                  </Link>
                </div>

                <div className="flex gap-2">
                  <CiMail className="w-10" />
                  <Link target="_blank" to="mailto:infouniqueglass@gmail.com">
                    infouniqueglass@gmail.com
                  </Link>
                </div>
              </div>
            </div>
          </Col>

          <Col lg={6} span={24} xl={6}>
            <div className="flex flex-col items-center justify-start pt-5">
              <h1 className="pb-12 text-2xl font-bold"> Learn More</h1>

              <ul className="flex flex-col space-y-5">
                <Link className="flex space-y-5 " to="/" onClick={() => window.scrollTo(0)}>
                  <li className="duration-150 hover:translate-x-1 hover:text-primary">
                    Home
                  </li>
                </Link>
                <Link className="flex space-y-5 " to="/about" onClick={() => window.scrollTo(0)}>
                  <li className="duration-150 hover:translate-x-1 hover:text-primary">
                    About Us
                  </li>
                </Link>
                <Link className="flex space-y-5 " to="/work" onClick={() => window.scrollTo(0)}>
                  <li className="duration-150 hover:translate-x-1 hover:text-primary">
                    Work
                  </li>
                </Link>
                <Link className="flex space-y-5 " to="/contact" onClick={() => window.scrollTo(0)}>
                  <li className="duration-150 hover:translate-x-1 hover:text-primary">
                    Contact
                  </li>
                </Link>
              </ul>
            </div>
          </Col>

          <Col lg={4} span={24} xl={4}>
            <div className="flex flex-col items-center justify-start pt-5">
              <h1 className="pb-12 text-2xl font-bold"> Follow</h1>

              <ul className="flex-col gap-5 space-y-5 flex-">
                <Link
                  to="https://www.instagram.com/unique_glass48/?igsh=dWczMzU0aHdocGR5&utm_source=qr"
                  target="_blank"
                  className="flex space-y-5 "
                >
                  <li className="duration-150 hover:translate-x-1 hover:text-primary">
                    Instagram
                  </li>
                </Link>
                <Link
                  className="flex space-y-5 "
                  to="https://wa.me/918980273288?text=Hello%2C%20I%20am%20interested%20in%20knowing%20more%20about%20Unique%20Glass.%20Can%20you%20please%20provide%20me%20with%20more%20details%3F"
                  target="_blank"
                >
                  <li className="duration-150 hover:translate-x-1 hover:text-primary">
                    Whatsapp
                  </li>
                </Link>
              </ul>
            </div>
          </Col>
        </Row>
      </footer>
      <span style={{ display: 'flex', justifyContent: "center", alignItems: 'center' }}>
        <p>©2024 Unique Glass. All rights reserved.</p>
      </span>
    </div>
  );
};

export default Footer;
