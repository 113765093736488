import React, { useState } from "react";
import { useRef } from "react";
import emailjs from '@emailjs/browser';
import Contect from "../assets/interiorglass.jpeg";
import { Link } from "react-router-dom";
import { IoCall } from "react-icons/io5";
import { IoMdMail } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";

const Contact = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_i58cqjv', 'template_zmv89we', form.current, 'ecR5usACLhsnfxi50')
      .then((result) => {
        console.log(result.text);
        setSuccessMsg(`Thank you ${name}, Your message has been sent successfully!`);
        setErrMsg("");
        setName("");
        setEmail("");
        setSubject("");
        setMessage("");

        setTimeout(() => {
          setSuccessMsg("");
        }, 5000);
      }, (error) => {
        console.log(error.text);
        setErrMsg("Error sending the message. Please try again later.");
        setSuccessMsg("");
      });
  };


  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  return (
    <div className="">
      <div className="relative w-full">
        <div>
          <img
            src={Contect}
            alt="work-landing"
            className=" w-screen  md:h-[500px]   object-cover"
          />
        </div>
        <div className="absolute text-4xl font-bold text-center text-white transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
          <div
            className={`mb-4 font-bold text-4xl md:text-7xl animate-slide-in`}
          >
            Contact
          </div>
          <div className="mb-6 text-sm font-bold animate-slide-in">
            <h2>
              <Link to="/">Home</Link>
              &gt; Contact
            </h2>
          </div>
        </div>
      </div>
      <div className="container mx-auto mt-9">
        <div className="flex flex-col gap-8 py-4 lg:flex-row">

          <div className="w-full px-10 lg:w-1/2">

            <div className="container justify-center gap-10 mt-10 md:px-20 md:flex mb-28">
              {/* Div1 Left side */}
              <div className="flex flex-col w-full px-3 py-10 text-white bg-gray-900 space-y-7 md:px-6">
                {/* Contact Information */}
                <div className="">
                  <span className="text-xl font-bold md:text-4xl">Contact Information</span>
                </div>
                <div className="">
                  <span className="text-lg">
                    Convinced yet? Let's make something great together.
                  </span>
                </div>

                <div className="flex flex-col md:flex">
                  {/* First Icon an text */}
                  <div className="flex justify-evenly">
                    {/* Icon */}
                    <div className="flex flex-col md:flex">
                      <div className="p-2 bg-white rounded-full">
                        <div className="p-4 text-3xl border border-black border-dashed rounded-full ">
                          <IoCall color="black" />
                        </div>
                      </div>
                    </div>
                    {/* Text */}
                    <div className="flex flex-col w-1/2">
                      <span className="text-lg">Call Us</span>
                      <Link className="text-xl break-words lg:text-xl" to="tel:+918980273288" target="_blank">
                        +91 8980273288
                      </Link>
                    </div>
                  </div>

                  {/* Second Icon an text */}
                  <div className="flex mt-5 justify-evenly">
                    {/* Icon */}
                    <div className="">
                      <div className="p-2 bg-white rounded-full">
                        <div className="p-4 text-3xl border border-black border-dashed rounded-full ">
                          <IoMdMail color="black" />
                        </div>
                      </div>
                    </div>
                    {/* Text */}
                    <div className="flex flex-col w-1/2">
                      <span className="text-lg">Make a Quote</span>
                      <Link className="break-words" target="_blank" to="mailto:infouniqueglass@gmail.com">
                        infouniqueglass@gmail.com
                      </Link>
                    </div>
                  </div>

                  {/* Third Icon an text */}
                  <div className="flex mt-5 justify-evenly">
                    {/* Icon */}
                    <div className="">
                      <div className="p-2 bg-white rounded-full">
                        <div className="p-4 text-3xl border border-black border-dashed rounded-full ">
                          <FaLocationDot color="black" />
                        </div>
                      </div>
                    </div>
                    {/* Text */}
                    <div className="flex flex-col w-1/2">
                      <span className="text-lg">Call Us 7/24</span>
                      <Link
                        to="https://maps.app.goo.gl/dKSPv2j3Fcc12HUc9"
                        target="_blank"
                      >
                        13,Dharamajivan society near Chamunda dairy hirawadi road 382345
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="">
                  <div className="py-2 text-center ">
                    <span className="text-xl font-semibold">FIND ME IN</span>
                  </div>
                  <div className="flex justify-center gap-1 mt-4 align-middle md:gap-3">

                    <Link
                      to="https://www.instagram.com/unique_glass48/?igsh=dWczMzU0aHdocGR5&utm_source=qr"
                      target="_blank"
                    >
                      <div className="p-3 mx-5 text-black transition-all duration-300 bg-white rounded-full hover:bg-slate-800 hover:text-white">
                        <span className="">
                          <FaInstagram />
                        </span>
                      </div>
                    </Link>
                    <Link
                      to="https://wa.me/918980273288?text=Hello%2C%20I%20am%20interested%20in%20knowing%20more%20about%20Unique%20Glass.%20Can%20you%20please%20provide%20me%20with%20more%20details%3F"
                      target="_blank"
                    >
                      <div className="p-3 mx-5 text-black transition-all duration-300 bg-white rounded-full hover:bg-slate-800 hover:text-white">
                        <span className="">
                          <FaWhatsapp />
                        </span>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden w-px bg-gray-300 lg:block"></div>
          <div className="px-10">
            <h1 className="pb-3 text-xl font-bold">Drop Us A Line</h1>
            <p className="mb-8 text-sm font-bold text-gray">
              Reach out to us from our contact form and we will get back to you
              shortly.
            </p>
            <div className="">
              <form ref={form} onSubmit={sendEmail}>
                <div className="flex flex-col w-full gap-10 mt-10 lgl:flex-row">
                  <div className="flex flex-col w-full gap-2 mb-4">
                    <p className="text-sm tracking-wide text-gray-400 uppercase">
                      Name
                    </p>
                    <input
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                      className={`w-full p-2 border border-gray-300 focus:outline-none ${errMsg === "Name is required!" ? "outline-designColor" : ""}`}
                      type="text"
                      name='name'
                      required='true'
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-2 mb-4">
                  <p className="text-sm tracking-wide text-gray-400 uppercase">
                    Email
                  </p>
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    className={`w-full p-2 border border-gray-300 focus:outline-none ${errMsg === "Email is required!" ?
                      "outline-designColor" : ""}`}
                    type="email"
                    name='email'
                    required='true'
                  />
                </div>
                <div className="flex flex-col gap-2 mb-4">
                  <p className="text-sm tracking-wide text-gray-400 uppercase">
                    Subject
                  </p>
                  <input
                    onChange={(e) => setSubject(e.target.value)}
                    value={subject}
                    className={`w-full p-2 border border-gray-300 focus:outline-none ${errMsg === "Plese give your Subject!" ?
                      "outline-designColor" : ""}`}
                    type="text"
                    name='subject'
                    required='true'
                  />
                </div>
                <div className="flex flex-col gap-2 mb-4">
                  <p className="text-sm tracking-wide text-gray-400 uppercase">
                    Message
                  </p>
                  <textarea
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                    className={`w-full p-2 border border-gray-300 focus:outline-none ${errMsg === "Message is required!" ?
                      "outline-designColor" : ""}`}
                    cols="30"
                    rows="8"
                    name='message'
                    required='true'
                  ></textarea>
                </div>
                <div className="w-full">
                  <button
                    type="submit"
                    className="w-full h-12 bg-[#141518] rounded-lg text-base text-gray-400 tracking-wider uppercase hover:text-white duration-300 hover:border-[1px] hover:border-designColor border-transparent"
                  >
                    Send Message
                  </button>
                </div>
              </form>
              {errMsg && (
                <p className="py-3 bg-gradient-to-r from-[#1e2024] to-[#23272b] shadow-shadowOne text-center text-orange-500 text-base tracking-wide animate-bounce">
                  {errMsg}
                </p>
              )}
              {successMsg && (
                <p className="py-3 bg-gradient-to-r from-[#1e2024] to-[#23272b] shadow-shadowOne text-center text-green-500 text-base tracking-wide animate-bounce">
                  {successMsg}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="w-full px-10 my-9">
        <div>
          <h1 className="my-10 text-4xl font-bold text-center">
            Our Location In Map
          </h1>
        </div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.3141060349662!2d72.6335975750927!3d23.048943179155287!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e87549ae9c3f1%3A0xee5cea0f25feae1a!2sdharmjivan%20Society!5e0!3m2!1sen!2sin!4v1721991325448!5m2!1sen!2sin"
          width="100%"
          height="450"
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>

    </div>
  );
};

export default Contact;
