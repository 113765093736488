import React, { useState } from "react";
import Logo from "../../assets/logo.jpg";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);
  const navigate = useNavigate();
  const handleExploreContactMore = () => {
    navigate('/contact');
  };

  return (
    <header className="text-white bg-black">
      <div className="container flex items-center justify-between px-4 py-4 mx-auto">
        <div className="flex items-center">
          <span>
            <Link to="/">
              <img
                src={Logo}
                alt="Logo"
                className="object-contain w-36 md:w-32 sm:ml-14"
              />
            </Link>
          </span>
        </div>
        <div className="hidden space-x-4 lg:flex gap-7">
          <NavLink
            to="/"
            className={({ isActive }) =>
              `block py-2 pr-4 pl-3 duration-200 border-b ${isActive ? "text-orange-700" : "text-white"
              } border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 hover:text-orange-700 lg:p-0 font-semibold`
            }
          >
            Home
          </NavLink>
          <NavLink
            to="/about"
            className={({ isActive }) =>
              `block py-2 pr-4 pl-3 duration-200 border-b ${isActive ? "text-orange-700" : "text-white"
              } border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 hover:text-orange-700 lg:p-0 font-semibold`
            }
          >
            About Us
          </NavLink>
          <NavLink
            to="/work"
            className={({ isActive }) =>
              `block py-2 pr-4 pl-3 duration-200 border-b ${isActive ? "text-orange-700" : "text-white"
              } border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 hover:text-orange-700 lg:p-0 font-semibold`
            }
          >
            Work
          </NavLink>
          <NavLink
            to="/contact"
            className={({ isActive }) =>
              `block py-2 pr-4 pl-3 duration-200 border-b ${isActive ? "text-orange-700" : "text-white"
              } border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 hover:text-orange-700 lg:p-0 font-semibold`
            }
          >
            Contact
          </NavLink>
        </div>

        <div className="items-center hidden space-x-4 lg:flex">
          <button className="px-3 py-1 mr-10 text-black bg-white rounded" onClick={handleExploreContactMore}>
            INQUIRY
          </button>
        </div>
        <button className="lg:hidden" onClick={toggleMenu}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="30"
            height="30"
            viewBox="0 0 50 50"
          >
            <path
              fill="white"
              d="M 0 9 L 0 11 L 50 11 L 50 9 Z M 0 24 L 0 26 L 50 26 L 50 24 Z M 0 39 L 0 41 L 50 41 L 50 39 Z"
            ></path>
          </svg>
        </button>
      </div>
      <div
        className={`fixed top-0 right-0 w-full h-full text-black bg-white transform transition-transform duration-300 ease-in-out z-10 ${isOpen ? "translate-x-0" : "translate-x-full"
          }`}
      >
        <button
          className="absolute text-black top-4 left-4"
          onClick={toggleMenu}
        >
          <span>
            <Link to="/">
              <img
                src={Logo}
                alt="Logo"
                className="object-contain w-36 md:w-32 sm:ml-14"
              />
            </Link>
          </span>
        </button>
        <button
          className="absolute text-black top-4 right-4"
          onClick={toggleMenu}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
          >
            <path d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
        <div className="transform translate-y-1/2 top-1/2">
          <nav className="flex flex-col px-2 py-2 space-y-4 text-center cursor-pointer">
            <a href="/home" className="py-4 text-black border-b-2 ">
              HOME
            </a>
            <a href="/about" className="py-2 text-black border-b-2">
              ABOUT
            </a>
            <a href="/work" className="py-2 text-black border-b-2">
              WORK
            </a>
            <a href="/contact" className="py-2 text-black border-b-2">
              CONTACT
            </a>
            <div className="flex justify-center pt-8">
              <button className="h-10 text-white bg-black rounded w-36">
                <a href="/contact">INQUIRY</a>
              </button>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Header;
