import React from "react";
import workLanding from "../assets//work-landing2.jpg";
import workCard from "../assets/work-landing.webp";
import blackMirror from "../assets/black-mirror.jpg";
import toughenedGlass from "../assets/Toughened-Glass.webp";
import Bronge from "../assets/Bronge-Mirror.webp";
import Mirror from "../assets/Mirror.jpeg";
import temple from "../assets/templeglass.jpeg";
import PuDesign from "../assets/PU-Design.jpeg";
import PuGlass from "../assets/pu-profile-shutter.jpg";
import toughenedGlass2 from "../assets/Toughened-Glass2.webp";
import partetionSanitary from "../assets/Glass-Partetion-Sanitary.jpg";
import reelingGlass from "../assets/reeling-glass.avif";
import balconyGlass from "../assets/balcony-glass.avif";
import namePlate from "../assets/name-plate.jpg";
import { Link } from "react-router-dom";

const Work = () => {
  const workData = [
    {
      name: "Black Mirror Design",
      desc: "Black Mirror Design merges functionality with elegance, transforming glass into a sophisticated, reflective surface. Ideal for modern interiors.",
      img: blackMirror,
    },
    {
      name: "Toughened Glass with Design",
      desc: "Toughened Glass with Design offers enhanced durability and style, perfect for both functional and decorative applications.",
      img: toughenedGlass,
    },
    {
      name: "Bronze Mirror",
      desc: "Bronze Mirror adds a warm, vintage charm to any space, providing both reflection and an elegant aesthetic.",
      img: Bronge,
    },
    {
      name: "Mirror",
      desc: "Our standard Mirror combines clarity and quality, suitable for any room needing a classic reflective surface.",
      img: Mirror,
    },
    {
      name: "Temple Design Glass",
      desc: "Temple Design Glass features intricate patterns, perfect for adding a touch of spirituality and beauty to your decor.",
      img: temple,
    },
    {
      name: "PU Design (Door)",
      desc: "PU Design (Door) offers robust and stylish door solutions with intricate PU patterns for a modern look.",
      img: PuDesign,
    },
    {
      name: "PU Glass Profile Shutter (Kitchen)",
      desc: "PU Glass Profile Shutter for kitchens blends functionality with sleek design, enhancing any modern kitchen.",
      img: PuGlass,
    },
    {
      name: "Toughened Glass Partition",
      desc: "Toughened Glass Partition ensures safety and style, ideal for creating modern, open spaces with a touch of elegance.",
      img: toughenedGlass2,
    },
    {
      name: "Glass Partition (Sanitary)",
      desc: "Glass Partition (Sanitary) provides a clean, contemporary solution for bathroom and sanitary spaces.",
      img: partetionSanitary,
    },
    {
      name: "Reeling Glass",
      desc: "Reeling Glass offers a secure and stylish option for railings, enhancing safety without compromising on design.",
      img: reelingGlass,
    },
    {
      name: "Balcony Glass",
      desc: "Balcony Glass combines safety and aesthetics, providing clear views and a modern look for balconies.",
      img: balconyGlass,
    },
    {
      name: "Name Plate",
      desc: "Customizable Name Plates in durable glass, offering a stylish way to display names and titles.",
      img: namePlate,
    },
  ];
  return (
    <>
      <div className="relative w-full">
        <div>
          <img
            src={workLanding}
            alt="work-landing"
            className=" w-screen  md:h-[500px]   object-cover"
          />
        </div>
        <div className="absolute text-4xl font-bold text-center text-white transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
          <div
            className={`mb-4 font-bold text-4xl md:text-7xl animate-slide-in`}
          >
            Our Work
          </div>
          <div className="mb-6 text-sm font-bold animate-slide-in">
            <h2>
              <Link to="/">Home</Link>
              &gt; Work
            </h2>
          </div>
        </div>
      </div>
      <div className="grid w-full grid-cols-1 my-10 work-card md:grid-cols-2 lg:grid-cols-3 ">
        {workData.map((data, index) => (
          <div
            key={index}
            className="relative h-[400px] my-10 w-[300px] md:w-[360px] lg:w-[380px] mx-auto rounded-lg"
          >
            <img
              src={data.img}
              alt="AirMax Pro"
              className="z-0 object-cover w-full h-full rounded-md"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-gray-900 to-transparent"></div>
            <div className="absolute text-left bottom-4 left-4">
              <h1 className="text-lg font-semibold text-white">{data.name}</h1>
              <p className="mt-2 text-sm text-gray-300">{data.desc}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Work;
