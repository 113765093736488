import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import coverGlassImg from "../assets/coverglass.jpeg";
import coverGlass2Img from "../assets/coverglass2.jpg";
import coverGlass3Img from "../assets/coverglass3.jpeg";
import glassImg from "../assets/glassimage.jpg";
import interiorGlassImg from "../assets/interiorglass.jpeg";
import glass1Img from "../assets/glass1.jpeg";
import stairsGlassImg from "../assets/stairsglass.jpg";
import livingroomGlass from "../assets/livingroomglass.jpeg";
import livingroom2Glass from "../assets/living2glass.jpeg";
import studyroomGlass from "../assets/studyroomglass.jpeg";
import studyroom1Glass from "../assets/studyroomglass2.jpeg";
import studyroom2Glass from "../assets/studyroomglass3.jpeg";
import templeGlass from "../assets/templeglass.jpeg";
import temple2Glass from "../assets/templeglass2.jpg";
import temple3Glass from "../assets/tampleglass3.jpeg";
import simpleGlass from "../assets/simpleglass.jpeg";
import simple2Glass from "../assets/simpleglass2.jpeg";
import simple3Glass from "../assets/simpleglass3.jpeg";
import mirrorGlassImg from "../assets/mirrorglass.jpeg";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import LeftContainer from "../Common/homeLeftContainer";
import RightContainer from "../Common/homeRightContaner";
import { useNavigate } from "react-router-dom";

const images = [
  {
    src: coverGlassImg,
    text: "NEW COLLECTION\nLOFT & TRENDS",
  },
  {
    src: coverGlass2Img,
    text: "BEST INSPIRATION LIST\nFURNITURE BRANDS",
  },
  {
    src: coverGlass3Img,
    text: "NEW COLLECTION\nINTERIOR DESIGN",
  },
];

const livingRoomImages = [
  {
    src: livingroomGlass,
    description: "Knife Set",
    
  },
  {
    src: stairsGlassImg,
    description: "Mortar and Pestle",
    
  },
  {
    src: livingroom2Glass,
    description: "Knife Set",
    
  },
];

const studyRoomImages = [
  {
    src: studyroomGlass,
    description: "Knife Set",
    
  },
  {
    src: studyroom1Glass,
    description: "Mortar and Pestle",
    
  },
  {
    src: studyroom2Glass,
    description: "Knife Set",
    
  },
];

const templeGlassImages = [
  {
    src: templeGlass,
    description: "Knife Set",
    
  },
  {
    src: temple2Glass,
    description: "Mortar and Pestle",
    
  },
  {
    src: temple3Glass,
    description: "Knife Set",
    
  },
];

const GlassImages = [
  {
    src: simpleGlass,
    description: "Knife Set",
    
  },
  {
    src: simple2Glass,
    description: "Mortar and Pestle",
    
  },
  {
    src: simple3Glass,
    description: "Knife Set",
    
  },
];

const PreviousButton = (props) => {
  const { onClick } = props;
  return (
    <div
      className="absolute p-2 text-2xl text-white top-1/2 left-4 hover:text-gray-200"
      style={{ zIndex: "39" }}
    >
      <button className="flex items-center justify-center w-8 h-10">
        <FaChevronLeft onClick={onClick} />
      </button>
    </div>
  );
};

const NextButton = (props) => {
  const { onClick } = props;
  return (
    <div
      className="absolute p-2 text-2xl text-white top-1/2 right-4 hover:text-gray-200"
      style={{ zIndex: "39" }}
    >
      <button className="flex items-center justify-center w-8 h-10">
        <FaChevronRight onClick={onClick} />
      </button>
    </div>
  );
};

const Home = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showText, setShowText] = useState(false);
  const [animationKey, setAnimationKey] = useState(Date.now());

  useEffect(() => {
    setShowText(false);
    setAnimationKey(Date.now());

    const imageDisplayTimeout = setTimeout(() => {
      setShowText(true);
    }, 500);

    return () => clearTimeout(imageDisplayTimeout);
  }, [currentIndex]);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (_, next) => {
      setCurrentIndex(next);
    },
    arrows: true,
    prevArrow: <PreviousButton />,
    nextArrow: <NextButton />,
  };

  const animationStyles = `
    @keyframes slideInFromTop {
      from {
        transform: translateY(-160%);
        opacity: 0;
      }
      to {
        transform: translateY(0);
        opacity: 1;
      }
    }
    .animate-slide-in {
      animation: slideInFromTop 0.8s ease-out;
    }
  `;

  const currentImage = images[currentIndex];

  const navigate = useNavigate();
  const handleExploreMoreClick = () => {
    navigate('/about');
};

  return (
    <div>
      <div className="relative w-screen h-screen">
        <style>{animationStyles}</style>
        <Slider {...settings}>
          {images?.map((image, index) => (
            <div key={index}>
              <img
                src={image.src}
                alt={`Slide ${index}`}
                className="object-cover w-screen h-screen"
              />
            </div>
          ))}
        </Slider>
        <div
          key={animationKey}
          className="absolute text-4xl font-bold text-center text-white transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
        >
          {showText && (
            <>
              <div className={`mb-4 font-thin text-6xl animate-slide-in`}>
                {currentImage.text.split("\n")[0]}
              </div>
              <div className={`mb-4 text-7xl animate-slide-in`}>
                {currentImage.text.split("\n")[1]}
              </div>
              <p className={`mb-6 text-sm font-bold animate-slide-in`}>
                {currentImage.text
                  .split("\n")
                  .slice(2)
                  .map((line, index) => (
                    <span key={index} className="block">
                      {line}
                    </span>
                  ))}
              </p>
              <button className="px-4 py-2 text-sm font-light text-white bg-green-500 border-2 border-green-500 animate-slide-in" onClick={handleExploreMoreClick}>
                READ MORE
              </button>
            </>
          )}
        </div>
      </div>
      <section className="bg-gray-100">
        <div className="container mx-auto">
          <div className="lg:flex">
            <LeftContainer imageSrc={glassImg} />
            <RightContainer
              title="SAGITTIS ULLAMCOR"
              subtitle="KITCHEN ACCESSORIES"
              productImages={livingRoomImages}
            />
          </div>
        </div>
      </section>
      <section className="bg-gray-100">
        <div className="container mx-auto">
          <div className="lg:flex">
            <RightContainer
              title="SAGITTIS ULLAMCOR"
              subtitle="LOFT DECORATION"
              productImages={studyRoomImages}
            />
            <LeftContainer imageSrc={interiorGlassImg} />
          </div>
        </div>
      </section>
      <section className="bg-gray-100">
        <div className="container mx-auto">
          <div className="lg:flex">
            <LeftContainer imageSrc={glass1Img} />
            <RightContainer
              title="SAGITTIS ULLAMCOR"
              subtitle="TRENDING FURNITURE"
              productImages={templeGlassImages}
            />
          </div>
        </div>
      </section>
      <section className="bg-gray-100">
        <div className="container mx-auto">
          <div className="lg:flex">
            <RightContainer
              title="SAGITTIS ULLAMCOR"
              subtitle="WOODEN DECOR"
              productImages={GlassImages}
            />
            <LeftContainer imageSrc={mirrorGlassImg} />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
